import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { PopupButton } from 'react-calendly';
import './modal.css';

const useStyles = makeStyles(theme => ({

}));

const CalendlyWidget = () => {

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);

    const css = document.createElement('script');
    script.setAttribute('href', 'https://assets.calendly.com/assets/external/widget.css');
    head.appendChild(css);

    return () => {
      head.removeChild(script);
      head.removeChild(css);
    }
  }, [])


  return (
    <>
      <div className="calendly-inline-widget" data-url="https://calendly.com/calicoding/app-consultation"></div>
    </>
  )
}

const CalendlyModal = ({ open, handleClose }) => {

  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={open}
        onClose={handleClose}
        disableScrollLock={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="paper">
            <CalendlyWidget />
          </div>
        </Fade>
      </Modal>
  )
}

export default function TransitionsModal() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Opened the schedule modal'
    })
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      <PopupButton
        className="buttonStyle"
        url="https://calendly.com/coastalpines/app-consultation"
        /*
        * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
        * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
        */
        rootElement={document.getElementById("root")}
        text="Let's Talk!"
      />
    </div>
  );
}