import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link, NavLink } from "react-router-dom";

import Logo from './logo';

const style = {
    appBarStyle: {
        backgroundColor: "rgba(238,247,248, 0.6)",
        padding: "30px 60px 30px 60px", // Top, Right, Bottom, Left
    },
    appBarImageStyle: {
    },
    buttonStyle: {
        background: "rgba(0,0,0,0) 0% 0% no-repeat padding-box",
        border: "2px solid #7044FA",
        color: "#7044FA",
        borderRadius: "4px",
    },
    buttonTextStyle: {
        font: 'Regular 28px/37px Segoe UI',
        color: "#7044FA",
        textAlign: "center",
        fontSize: '18px',
    },
    menuTextStyle: {
        font: 'Regular 28px/37px Segoe UI',
        color: "rgba(30,30,30,1.0)",
        fontSize: '16px',
    },
    section1Style: {
        marginTop: "0px"
    },
    logoImg: {
        marginRight: "16px"
    }
}

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.handleScrollTo = this.handleScrollTo.bind(this);
        this.handleScrollToTop = this.handleScrollToTop.bind(this);
    }

    state = {
        visible: false,
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };


    onProjectName(project_name) {
        this.props.projectNameChanged(project_name);
    }

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleScrollTo() {
        const section4 = document.getElementById("section4ID");
        section4.scrollIntoView({ behavior: "smooth" })
    }

    handleScrollToTop() {

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    state = {
        visible: false,
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    componentWillUnmount() {
        // whatever you need to cleanup the widgets code
    };

    render() {
        return (
            <AppBar className={this.props.classes.appBarStyle} position="sticky" id="headerID">
                <Grid container justify="space-between">

                    <Logo handleScrollToTop={this.handleScrollToTop}/>

                    <Grid container item direction="row" justify="space-evenly" alignItems="center" xl lg md sm xs >
                        {/* Menu */}
                        {/* <NavLink to="/about" style={{ textDecoration: 'none' }}>
                                <Typography className={this.props.classes.menuTextStyle}>
                                    ABOUT
                                </Typography>
                            </NavLink>
                            <NavLink to="/work" style={{ textDecoration: 'none' }}>
                                <Typography className={this.props.classes.menuTextStyle}>
                                    WORK
                                </Typography>
                            </NavLink>
                            <NavLink to="/team" style={{ textDecoration: 'none' }}>
                                <Typography className={this.props.classes.menuTextStyle}>
                                    TEAM
                                </Typography>
                            </NavLink>
                            <NavLink to="/community" style={{ textDecoration: 'none' }}>
                                <Typography className={this.props.classes.menuTextStyle}>
                                    COMMUNITY
                                </Typography>
                            </NavLink>
                            <NavLink to="/contact" style={{ textDecoration: 'none' }}>
                                <Typography className={this.props.classes.menuTextStyle}>
                                    CONTACT
                                </Typography>
                            </NavLink> */}
                    </Grid>

                    <Grid container item xl lg md sm xs justify="flex-end" alignItems="center">
                        {/* Book Now Button */}
                        <Button size="large" className={this.props.classes.buttonStyle} onClick={this.handleScrollToTop}>
                            Contact Us
                        </Button>
                    </Grid>

                </Grid>
            </AppBar>
        )
    }
}

const mapStateToProps = state => {
    return {
        visible: state.visible
    }
}

export default connect(mapStateToProps)(withStyles(style)(NavBar));