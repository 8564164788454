import React, {Component} from 'react';
import Main from './components/Main';
import { Provider } from 'react-redux';
import {BrowserRouter as Router} from "react-router-dom";

import {ConfigureStore} from './configureStore';

const store = ConfigureStore();

class App extends Component {
  render() {

    return (
      <Provider store={store}>
        <Router>
          <Main />
        </Router>
      </Provider>
    )
  }
}

export default App;