import React, {Component} from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';

import NavBar from '../common/navbar';
import ContactSection from '../sections/contact-section';
import Footer from '../common/footer';


const style = {
    contactSectionStyle: {
        marginTop: "0px",
        height: "858px"
    },
    footerStyle: {
        height: "450px"
    }
}

class ContactPage extends Component {
    render() {
        return (
            <div style={{position: 'relative'}}>
                <div style={{zIndex: '9'}}>

                    {/* Navbar */}
                    <NavBar />

                    {/* Contact Section */}
                    <Grid id="section1ID" container className={this.props.classes.contactSectionStyle}>
                        <ContactSection />
                    </Grid>

                    {/* Footer */}
                    <Grid id="footerID" container className={this.props.classes.footerStyle}>
                        <Footer />
                    </Grid>
                </div>
            </div>
        )
    }
}

const mapStoreToProps = state => {
    return {
    }
}

const mapsDispatchToProps = (dispatch) => ({
})

export default connect(mapStoreToProps, mapsDispatchToProps)(withStyles(style)(ContactPage));