import React, {Component} from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';

import hero  from '../../static/images/hero.jpg';
import TransitionsModal from '../modal.js'




const style = {
    heroImage: {
        backgroundImage: `url(${hero})`,/* The image used */
        backgroundColor: '#cccccc', /* Used if the image is unavailable */
        height: '100%', /* You must set a specified height */
        flex: 1,
        // backgroundSize: 'auto, cover',
        backgroundPosition: 'center', /* Center the image */
        backgroundRepeat: 'no-repeat', /* Do not repeat the image */
        // backgroundSize: 'cover', /* Resize the background image to cover the entire container */
        backgroundSize: '100% 100%', 
        overflow: 'hidden'
    },
    heroImageFilter: {
        // backgroundColor: 'rgba(238,247,248, 0.5)',
        background: 'linear-gradient(to right bottom, rgba(44,121,163,0.85), rgba(44,121,163,0.85))',
        display: 'flex',
        flex: 1,
        height: '100%',
        alignContent:'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    centerBox: {
        background: 'rgba(255,255,255,0.75)',
        borderRadius:'5px',
        width: '820px',
        height: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '0px',
        alignContent:'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    centerBoxContent: {
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'center',
        alignContent: 'center',
        backgroundColor: 'rgba(255,0,0, 0.0)',
        width: '80%'
    },
    heroTitleContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgba(0,0,0,0.0)',
        textAlign: 'center'
    },
    heroTitle: {
        paddingTop: '0px',
        marginBottom: '0px',
        marginTop: '0px',
        font: 'Semibold 65px/86px Segoe UI',
        color: '#2C79A3',
        opacity: 1,
        fontSize: '50px',
    },
    heroSubTitle: {
        paddingTop: '10px',
        textAlign: 'center',
        font: 'Regular 38px/51px Segoe UI',
        color: '#2C79A3',
        opacity: 1,
        fontSize: '25px',
        backgroundColor: 'rgba(0,0,0,0.0)',
        marginTop: '0px',
        marginBottom: '0px'
    },
    heroTextList: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        paddingTop: '0px',
        paddingBottom: '20px',
    },
    heroTextItem: {
        color: '#2C79A3',
        font: 'Regular 38px/51px Segoe UI',
        fontSize: '25px'
    },
    buttonContainer: {
        alignContent:'center',
        justifyContent:'center', 
        display: 'flex',
        backgroundColor: 'rgba(0,0,0, 0.0)',
        flex: 1,
        // marginLeft: '2.5%',
        // marginRight: '2.5%'
    },
    buttonStyle: {
        background:' #2C79A3 0% 0% no-repeat padding-box',
        'border-radius': '4px',
        opacity: 1,
        flex: 1,
        'text-align': 'center',
        font: 'Regular 38px/51px Segoe UI',
        'letter-spacing': '0',
        textColor : '#FFFFFF',
        alignSelf:'center'
    },
    buttonTextStyle: {
        "font-family": "Segoe UI",
        color:" #fff",
        fontSize: '38px',
        textAlign: "left",
    }
}

class TeamSection extends Component {


    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
      }

    state = {
        visible: false,
    }

    showModal = () => {
        this.setState({
            visible: true
          });
      };

    
    onProjectName(project_name) {
    this.props.projectNameChanged(project_name);
    }

    handleCancel = e => {
        console.log(e);
        this.setState({
          visible: false,
        });
    };

    renderModal() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
            return(
                <TransitionsModal/>
            )
    };


    componentWillUnmount() {
    // whatever you need to cleanup the widgets code
    };

    render() {
        return (
            <div className={this.props.classes.heroImage} >
                <div className={this.props.classes.heroImageFilter}>
                    <div className={this.props.classes.centerBox}>
                        <div className={this.props.classes.centerBoxContent}>
                            <div className={this.props.classes.heroTitleContainer}>
                                <h1 className={this.props.classes.heroTitle}>Our Team</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      visible: state.visible
    }
  }

export default connect(mapStateToProps)(withStyles(style)(TeamSection));