import React, { Component } from 'react';

import { withStyles } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core';

import Logo from './logo'
import './css/footer.css';




class Footer extends Component {
    constructor(props) {
        super(props);
        this.handleScrollTo = this.handleScrollTo.bind(this);
        this.handleScrollToTop = this.handleScrollToTop.bind(this);
    }

    handleScrollTo() {
        const section4 = document.getElementById("section4ID");
        section4.scrollIntoView({ behavior: "smooth" })
    }

    handleScrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render() {
        {/* Current year */}
        let year = new Date().getFullYear();

        return (
            <div className="footerBackground">
                <div className="footerContentContainer">
                    <Logo className="footerLogo" handleScrollToTop={this.handleScrollToTop} />
                    <div className="footerLinksRowContainer">
                        <div className="footerLinksContainer">
                            <h3 className="footerLinkText">FAQ</h3>
                            <h3 className="footerLinkText">Contact Us</h3>
                            <h3 className="footerLinkText">Join the team</h3>
                        </div>
                        <div>
                            <Button className="footerButton" variant="outlined" onClick={this.handleScrollToTop}>
                                <Typography className="buttonTextStyle">
                                    Contact Us
                                </Typography>
                            </Button>
                        </div>
                    </div>
                    <div className="footerDividerLineContainer">
                        <div className="footerDividerLine">
                        </div>
                    </div>
                    <div className="footerBottomContainer">
                        <div className="footerLegalDocsContainer">
                            <h3 className="footerLegalText">Terms of Service</h3>
                            <div className="footerSpacer"></div>
                            <h3 className="footerLegalText">Privacy Policy</h3>
                        </div>
                        <div>
                            <h3 className="footerCopyright">Copyright © Cali Coding {year}</h3>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (Footer);