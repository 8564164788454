import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import LandingPage from './pages/landingPage';
import AboutPage from './pages/aboutPage';
import WorkPage from './pages/workPage';
import TeamPage from './pages/teamPage';
import CommunityPage from './pages/communityPage';
import ContactPage from './pages/contactPage';


class Main extends Component {
    render() {

        const LandingPageComponent = (props) => {
            return (
                <LandingPage {...props}/>
            )
        }

        const AboutPageComponent = (props) => {
            return (
                <AboutPage {...props}/>
            )
        }

        const WorkPageComponent = (props) => {
            return (
                <WorkPage {...props}/>
            )
        }

        const TeamPageComponent = (props) => {
            return (
                <TeamPage {...props}/>
            )
        }

        const CommunityPageComponent = (props) => {
            return (
                <CommunityPage {...props}/>
            )
        }

        const ContactPageComponent = (props) => {
            return (
                <ContactPage {...props}/>
            )
        }

        const trackingId = "UA-134348656-2";
        ReactGA.initialize(trackingId);
        ReactGA.pageview(window.location.pathname + window.location.search)


        return (
            <Switch>
                {/* <Route path="*" component={LandingPageComponent}/> */}
                <Route exact path='/' component={LandingPageComponent} />
                <Route exact path="/about" component={AboutPageComponent}/>
                <Route exact path="/work" component={WorkPageComponent}/>
                <Route exact path="/team" component={TeamPageComponent}/>
                <Route exact path="/community" component={CommunityPageComponent}/>
                <Route exact path="/contact" component={ContactPageComponent}/>
            </Switch>
        )
    }
}


const mapStoreToProps = state => {
    return {
    }
}

const mapsDispatchToProps = (dispatch) => ({
})

export default withRouter(connect(mapStoreToProps, mapsDispatchToProps)(Main));