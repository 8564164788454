import React, { Component } from 'react';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import vHopOnCapture from '../../static/images/vHopOnCapture.jpg'
import addisResturantCapture from '../../static/images/addisResturantCapture.jpg'
import socalSudsCapture from '../../static/images/soCalSudsCapture.jpg'
import lottie from 'lottie-web';
import pillboxData from '../../static/data/pillbox.json'
import mytabData from '../../static/data/mytab.json'
import soCalSudsData from '../../static/data/socalsuds.json'
import addisResturantData from '../../static/data/pillbox.json'
import vHopOnData from '../../static/data/pillbox.json'
import EM2Data from '../../static/data/em2.json'
import './css/section2.css';


let pillboxLottie = null;
let mytabLottie = null;
let soCalSudsLottie = null;
let addisResturantLottie = null;
let vHopOnLottie = null;
let EM2Lottie = null;

class Section2 extends Component {

    constructor(props) {
        super(props);
        this.state = { isStopped: false, isPaused: false, pillboxHover: false };
    }

    componentDidMount() {

        pillboxLottie = lottie.loadAnimation({
            container: this.pillboxAnimationContainer, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: pillboxData // the path to the animation json
        });

        mytabLottie = lottie.loadAnimation({
            container: this.mytabAnimationContainer, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: mytabData // the path to the animation json
        });

        soCalSudsLottie = lottie.loadAnimation({
            container: this.soCalSudsAnimationContainer, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: soCalSudsData // the path to the animation json
        });

        addisResturantLottie = lottie.loadAnimation({
            container: this.addisResturantContainer, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: addisResturantData // the path to the animation json
        });

        vHopOnLottie = lottie.loadAnimation({
            container: this.vHopOnContainer, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: vHopOnData // the path to the animation json
        });

        EM2Lottie = lottie.loadAnimation({
            container: this.EM2AnimationContainer, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: EM2Data // the path to the animation json
        });
    }

    handlePillboxStop = () => {
        pillboxLottie.stop();
    }
    handlePillboxPlay = () => {
        pillboxLottie.play();
    }
    handleMytabStop = () => {
        mytabLottie.stop();
    }
    handleMytabPlay = () => {
        mytabLottie.play();
    }
    handleSoCalSudsStop = () => {
        soCalSudsLottie.stop();
    }
    handleSoCalSudsPlay = () => {
        soCalSudsLottie.play();
    }
    handleEM2Play = () => {
        EM2Lottie.play();
    }
    handleEM2Stop = () => {
        EM2Lottie.stop();
    }

    onMouseOver = () => (
        // this.setState({ pillboxHover: true }
        console.log('hovering')
    );

    onMouseOut = () => (
        // this.setState({ pillboxHover: false }
        console.log('not hovering')
    );

    // pillboxRender = () => {
    //     return(

    //         this.state.shadow ?
    //         <div onmouseover={this.handlePillboxStop} onmouseout={this.handlePillboxPlay} style={{width: 450, margin: '0 auto'}} ref={ ref => this.pillboxAnimationContainer = ref}></div>
    //         :
    //         <div style={{width: 450, height:450, margin: '0 auto'}}> im</div>

    //     )
    // }

    render() {

        return (
            <div className='mainGridStyle'>

                <Grid container>
                    <Typography variant="h2" className='titleStyle'>
                        Featured Projects
                    </Typography>
                </Grid>

                <Grid container align="center" justify="space-between" spacing={8}>

                    <Grid container item align="center" spacing={4}>

                        {/* Card 1 */}

                        <Grid item xl lg md  id="pillbox-card">
                            <Card className="card" elevation={8}>
                                <CardActionArea>

                                    <CardMedia
                                        className="media"
                                        component={() => <div className="lottie" onMouseEnter={this.handlePillboxPlay} onMouseLeave={this.handlePillboxStop} ref={ref => this.pillboxAnimationContainer = ref}></div>}
                                        title="Precision Pillbox"
                                    />

                                    <CardContent className="content">
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Precision Pillbox
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            An iOS and Android app for managing medications and tracking responses
                                        </Typography>
                                    </CardContent>

                                </CardActionArea>

                            </Card>
                        </Grid>

                        {/* Card 2 */}
                        <Grid item xl lg md  id="socal-suds-card">
                            <Card className='card' elevation={8}>
                                <CardActionArea>

                                    <CardMedia
                                        className="media"
                                        component={() => <div className="lottie" onMouseEnter={this.handleSoCalSudsPlay} onMouseLeave={this.handleSoCalSudsStop} ref={ref => this.soCalSudsAnimationContainer = ref}></div>}
                                        title="SoCal Suds"
                                    />

                                    <CardContent className="content">
                                        <Typography gutterBottom variant="h5" component="h2">
                                            SoCal Suds
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            A web application built to allow customers to schedule appointments directly with professional mobile detailers in the Southern California region
                                        </Typography>
                                    </CardContent>

                                </CardActionArea>

                            </Card>
                        </Grid>

                        {/* Card 3 */}
                        <Grid item xl lg md  id="mytab-card">
                            <Card className="card" elevation={8}>
                                <CardActionArea>

                                    <CardMedia
                                        className="media"
                                        component={() => <div className="lottie" onMouseEnter={this.handleMytabPlay} onMouseLeave={this.handleMytabStop} ref={ref => this.mytabAnimationContainer = ref}></div>}
                                        title="My Tab"
                                    />

                                    <CardContent className="content">
                                        <Typography gutterBottom variant="h5" component="h2">
                                            My Tab
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            An iOS and Android app for businesses to manage their customer's debt.
                                        </Typography>
                                    </CardContent>

                                </CardActionArea>

                            </Card>
                        </Grid>

                    </Grid>
                    <Grid container item align="center" spacing={4}>

                        {/* Card 4 */}

                        <Grid item xl lg md  id="addis-restaurant-card">
                            <Card className="card" elevation={8}>
                                <CardActionArea>

                                    <CardMedia
                                        className="media"
                                        image={addisResturantCapture}
                                        title="Addis Resturant"

                                    />

                                    <CardContent className="content">
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Addis Resturant
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            A single page website built for a local San Diego resturant to display menu items and location information
                                        </Typography>
                                    </CardContent>

                                </CardActionArea>

                            </Card>
                        </Grid>

                        {/* Card 5 */}
                        <Grid item xl lg md  id="em2-card">
                            <Card className="card" elevation={8}>
                                <CardActionArea>

                                    <CardMedia
                                        className="media"
                                        component={() => <div className="lottie" onMouseEnter={this.handleEM2Play} onMouseLeave={this.handleEM2Stop} ref={ref => this.EM2AnimationContainer = ref}></div>}
                                        title="EM2"
                                    />

                                    <CardContent className="content">
                                        <Typography gutterBottom variant="h5" component="h2">
                                            EM2
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            A mass messaging system built to send urgent text, image and voice messages to large groups of people rapidly
                                        </Typography>
                                    </CardContent>

                                </CardActionArea>

                            </Card>
                        </Grid>

                        {/* Card 6 */}
                        <Grid item xl lg md  >
                            <Card className="card" elevation={8}>
                                <CardActionArea>

                                    <CardMedia
                                        className="media"
                                        image={vHopOnCapture}
                                        title="Hop On"

                                    />

                                    <CardContent className="content">
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Hop On
                                        </Typography>
                                        <Typography variant="body1" component="p">
                                            A web application platform that offers small businesses the ability to schedule same day deliver's with a network of local drivers.
                                        </Typography>
                                    </CardContent>

                                </CardActionArea>

                            </Card>
                        </Grid>

                    </Grid>

                </Grid>
            </div>
        )
    }
}

const mapStoreToProps = state => {
    return {
    }
}

const mapsDispatchToProps = (dispatch) => ({
})

export default connect(mapStoreToProps, mapsDispatchToProps)((Section2));