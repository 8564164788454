import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../static/images/logo.png';

const Logo = ({ handleScrollToTop }) => {
    return (
        <>
            {/* Logo */}
            <Link to="/" style={{textDecoration: 'none'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img src={logo} onClick={handleScrollToTop} height="36" alt="" style={{marginRight: '8px'}}></img>
                    <div style={{fontSize: '24px', color: '#171234', fontWeight: '600', lineHeight: '24px'}}>Cali Coding</div>
                </div>
            </Link>
        </>
    )
}

export default Logo;
