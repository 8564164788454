import { createStore } from 'redux';
import rootReducer from './rootReducer';

export const ConfigureStore = () => {

    const store = createStore(
        rootReducer,
    )

    return store;
}