import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactGA from 'react-ga';
// import auth from './auth.ts'; // Sample authentication provider

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';

import NavBar from '../common/navbar';
import Section1 from '../sections/section1';
import Section2 from '../sections/section2';
import Footer from '../common/footer';
// Css for landing page
import './css/landingPage.css';

const style = {

}
class LandingPage extends Component {

    componentDidMount() {
        const script = document.createElement("script");
        script.setAttribute('src', 'https://embed.small.chat/T64V2FSRWGTEQDE7A4.js');
        script.async = true;
        document.body.appendChild(script);

        // ReactGA.set({
        // userId: auth.currentUserId(),
        // // any data that is relevant to the user session
        // // that you would like to track with google analytics
        // })
    }

    render() {
        return (
                <div style={{ zIndex: '9' }}>

                    {/* Navbar */}
                    <NavBar />

                    {/* Section 1 */}
                    <Section1 />

                    {/* Section 2 */}
                    <Grid id="section2ID" container className='section2Style'>
                        <Section2 />

                    </Grid>
                    <Grid id="footerID" container className='footerStyle'>
                        <Footer />
                    </Grid>

                    {/* Section 3 */}
                    {/* <Grid id="section3ID" container className={this.props.classes.section3Style}>
                        <Section3 />
                    </Grid> */}

                    {/* Section 4 */}
                    {/* <Grid id="section4ID" container className={this.props.classes.section4Style}>
                        <Section4 />
                    </Grid> */}

                    {/* Section 5 */}
                    {/* <Grid id="section5ID" container className={this.props.classes.section5Style}>
                        <Section5 />
                    </Grid> */}

                    {/* Footer */}

                </div>
        )
    }
}

const mapStoreToProps = state => {
    return {
    }
}

const mapsDispatchToProps = (dispatch) => ({
})

export default connect(mapStoreToProps, mapsDispatchToProps)(withStyles(style)(LandingPage));