import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import './css/section1.css';
import hero from '../../static/images/hero.png';
import TransitionsModal from '../modal.js'
import Typer from '../common/typer.js'



class Section1 extends Component {
    state = {
        visible: false,
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };


    onProjectName(project_name) {
        this.props.projectNameChanged(project_name);
    }

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };


    componentWillUnmount() {
        // whatever you need to cleanup the widgets code
    };

    render() {
        return (
            <div className="heroContainer">
                <div className="heroContent">
                    <h1 className="heroTitle">Make your vision come to life.</h1>
                    <h3 className="heroSubTitle">We help you create apps that serve your customers.</h3>
                    <TransitionsModal />
                </div>
                <div className="heroImage">
                    <img src={hero} width="100%" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        visible: state.visible
    }
}

export default connect(mapStateToProps)((Section1));